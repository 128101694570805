<template>
  <b-card>
    <div class="d-flex justify-content-between mb-8">
      <h3 class="text-capitalize my-auto">{{ $t('label.overview') }}</h3>

      <!-- begin:: DropDown Actions -->
      <div class="dropdown-actions-container">
        <b-dropdown
          size="sm"
          :text="$t(`enumReviewStatus.${reviewObj.status}`)"
          :variant="statusColor(reviewObj.status)"
          :disabled="
            !checkAccessRight(module.REVIEW, [action.REVIEW_UPDATE])
            || [reviewStatus.APPROVED, reviewStatus.DECLINED].includes(reviewObj.status)
          "
        >
          <template v-for="(item, index) in availableUpdateStatus">
            <b-dropdown-item
              :key="index"
              class="d-flex align-center cursor-pointer"
              @click="openUpdateStatusModal(item.key)"
            >
              <label
                class="label label-lg label-inline cursor-pointer w-100 py-5"
                :class="`label-light-${statusColor(item.key)}`"
              >
                {{ $t(`label.${item.label}`) }}</label
              >
            </b-dropdown-item>
          </template>
        </b-dropdown>
      </div>
      <!-- end:: DropDown Actions -->
    </div>
    <div class="mb-8">
      <template v-for="(i, index) in basicInfoList">
        <div :key="i + index" class="mb-3 d-flex justify-content-between">
          <div class="font-weight-bolder text-capitalize">
            {{ $t(`label.${i.name}`) }}:
          </div>
          <div class="text-end text-gray-600">
            <template v-if="!i.field">
              {{ reviewObj[i.key] }}
            </template>
            <template v-else-if="['reviewer', 'reviewee'].includes(i.field) && reviewObj[i.field]">
              <router-link
                :to="{
                  name: routeName.PROFILE_DETAILS_DETAILS,
                  params: {
                    id: reviewObj[i.field].id
                  }
                }"
              >{{ reviewObj[i.field].name }}
              </router-link>
            </template>
          </div>
        </div>
      </template>
    </div>
    <b-modal
      v-model="modal"
      :title="$t('label.updateReviewStatus')"
      centered
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      header-class="text-capitalize"
      @cancel="cancelAction"
      @ok="okAction"
    >
      <div v-if="selectedStatus == reviewStatus.APPROVED">
        {{
          $t('message.requestUpdateReviewStatusMessage', [
            $t('label.approve').toLowerCase()
          ])
        }}
      </div>
      <div v-else-if="selectedStatus == reviewStatus.DECLINED">
        <div class="mb-4">
          {{
            $t('message.requestUpdateReviewStatusMessage', [
              $t('label.decline').toLowerCase()
            ])
          }}
        </div>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import { MODULE, ACTION, ROUTE_NAME } from '@/core/constants';
  import { ReviewStatus } from '@/core/constants/enums';
  import { i18nHelper } from '@/core/utils';
  import {
    REVIEWS_UPDATE_REVIEW,
    REVIEWS_INITIAL_UPDATE_REVIEW_STATE,
  } from '@/core/store/reviews.module';

  export default {
    name: 'ReviewDetailsOverviewCard',
    mixins: [commonMixin],
    props: {
      reviewObj: {
        type: Object,
        default: () => {}
      },
    },
    data: () => ({
      basicInfoList: [
        {
          key: 'reviewer',
          name: 'reviewer',
          field: 'reviewer'
        },
        {
          key: 'reviewee',
          name: 'reviewee',
          field: 'reviewee'
        },
        {
          key: 'jobTitle',
          name: 'title',
        },
        {
          key: 'jobProductionStart',
          name: 'productionStartDate',
        },
        {
          key: 'jobProductionEnd',
          name: 'productionEndDate',
        },
        {
          key: 'createdAt',
          name: 'submittedAt',
        }
      ],
      module: MODULE,
      action: ACTION,
      reviewStatus: ReviewStatus,
      routeName: ROUTE_NAME,
      availableUpdateStatus: [
        {
          key: ReviewStatus.APPROVED,
          label: 'approve'
        },
        {
          key: ReviewStatus.DECLINED,
          label: 'decline'
        }
      ],
      selectedStatus: null,
      modal: false
    }),
    computed: {
      updateReviewStatusComplete() {
        return this.$store.state.reviews.updateReview.complete;
      }
    },
    watch: {
      updateReviewStatusComplete(value) {
        let response = this.$store.state.reviews.updateReview;
        let title = i18nHelper.getMessage('label.updateReview');
        let initialStateAction = REVIEWS_INITIAL_UPDATE_REVIEW_STATE;
        let successAction = () => {
          this.updateReviewStatusCompleteAction();
        };

        if (value) {
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    methods: {
      statusColor(value) {
        let color = {
          [ReviewStatus.PENDING]: 'warning',
          [ReviewStatus.APPROVED]: 'success',
          [ReviewStatus.DECLINED]: 'danger',
          approve: 'success',
          decline: 'danger'
        };

        return color[value];
      },
      updateReviewStatusCompleteAction() {
        this.modal = false;
        this.$emit('update-review-success');
      },
      updateStatus() {
        this.$store.dispatch(REVIEWS_UPDATE_REVIEW, {
          id: this.reviewObj.id,
          data: {
            status: this.selectedStatus
          }
        })
      },
      openUpdateStatusModal(value) {
        this.selectedStatus = value;
        this.modal = true;
      },
      okAction(event) {
        event.preventDefault();
        this.updateStatus();
      },
      cancelAction() {
        this.selectedStatus = null;
        this.modal = false;
      },
    }
  };
</script>

<style></style>
