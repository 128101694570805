<template>
  <div>
    <!--begin: Details Section-->
    <b-row no-gutters class="w-100 mt-4">
      <!--begin: Col 1-->
      <b-col cols="12" md="4">
        <!-- begin: Overview -->
        <ReviewDetailsOverviewCard
          :reviewObj="reviewObj"
          class="mb-6"
          @update-review-success="initialReviewDetails"
        />
        <!-- end: Overview -->
      </b-col>
      <!--end: Col 1-->

      <!--begin: Col 2-->
      <b-col cols="12" md="8" class="mt-4 mt-md-0 pl-md-4 pl-lg-8">
        <b-card>
          <AppDescription :contentHolder="reviewInformationList" :value="reviewObj" class="mb-8">
            <template slot="rating">
              <b-form-rating 
                v-model="reviewObj.rating" 
                class="custom-form-rating"
                no-border
                inline
                readonly
                size="lg"
                variant="primary"
              ></b-form-rating>
            </template>
          </AppDescription>
        </b-card>
      </b-col>
      <!--end Col 2-->
    </b-row>
    <!--end: Details Section-->
  </div>
</template>

<script>
  import ReviewDetailsOverviewCard from '@/views/components/review/ReviewDetailsOverviewCard';
  import AppDescription from '@/views/components/bases/AppDescription';
  import commonMixin from '@/core/mixins/common.mixin';
  import { ROUTE_NAME, MODULE, ACTION } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import {
    REVIEWS_GET_REVIEW,
    REVIEWS_INITIAL_REVIEW_STATE
  } from '@/core/store/reviews.module';

  export default {
    name: 'ReviewDetails',
    mixins: [commonMixin],
    components: {
      ReviewDetailsOverviewCard,
      AppDescription
    },
    data: () => ({
      routeName: ROUTE_NAME,
      moduleName: MODULE.REVIEW,
      permissionAction: ACTION,
      id: null,
      reviewObj: {},
      reviewInformationList: [
        {
          head: i18nHelper.getMessage('label.rating'),
          body: {
            key: 'rating',
            slotName: 'rating'
          },
          cols: 12
        },
        {
          head: i18nHelper.getMessage('label.comment'),
          body: {
            key: 'comment',
          },
          cols: 12
        },
      ]
    }),
    computed: {
      reviewDetailsComplete() {
        return this.$store.state.reviews.review.complete;
      }
    },
    watch: {
      reviewDetailsComplete() {
        let response = this.$store.state.reviews.review;
        let title = i18nHelper.getMessage('label.getReviewDetails');
        let initialStateAction =
          REVIEWS_INITIAL_REVIEW_STATE;
        let successAction = (response) => {
          this.importReviewObj(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    created() {
      this.initialReviewDetails();
    },
    methods: {
      importReviewObj(response) {
        let data = response.data;

        this.reviewObj = data;
      },
      getReviewDetails(id) {
        this.$store.dispatch(REVIEWS_GET_REVIEW, {
          id
        });
      },
      initialReviewDetails() {
        let id = Number(this.$route.params.id);
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.review'),
              route: { name: ROUTE_NAME.REVIEW }
            },
            { title: i18nHelper.getMessage('label.reviewDetails') }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };

        this.id = id;
        this.getReviewDetails(id);
        this.initBreadCrumb(breadcrumbData);
        window.KTUtil.scrollTop();
      }
    }
  };
</script>

<style lang="scss">
.custom-form-rating {
  padding: 0 !important;
}
</style>
